export const scenarios = [
    {
      id: 1,
      delay: 10,
      message: "Hi, I'm your AI partner.",
      next: 2
    },
    {
        id: 2,
        delay: 2000,
        message: "How would you like to create your video today?",
        responseType: "button",
        options: [
            { label: "AI", next: 333, componentType: 'AICard' },
            { label: "URL", next: 212, componentType: 'URLCard' },
            { label: "Template", next: 31, componentType: 'TemplateCard' }, 
            { label: "Scratch", next: 414, componentType: 'ScratchCard' },
            { label: "Text", next: 616, componentType: 'TextCard' },
        ]
    },
    {
        id: 333,
        delay: 1000,
        message: "Sounds good! What would you like the video to be about?",
        responseType: "none",
        apiCall: false,
        next:3
    },
    {
      id: 3,
      delay: 1000,
      responseType: "ideaCustomInput",
      apiCall: false,
      next:4
    },
    {
        id: 4,
        delay: 1000,
        message: "Lets create an outline for this topic.",
        apiCall: false,
        responseType: "none",
        next:5
    },
    {
        id: 5,
        delay: 0,
        apiCall: true,
        apiName: 'outlineAPI',  // Now calls outlineAPI directly
        responseType: "none",
        next: 9  // Skip to outline display
    },

    {
        id:7,
        delay: 1000,
        apiCall: false,
        message:'Lets create an outline for this topic',
        responseType:'none',
        next:8
    },
    {
        id:8,
        delay: 0,
        apiCall: true,
        apiName:'outlineAPI',
        responseType: "none",
        next:9
    },
    {
        id:9,
        delay: 0,
        apiCall: false,
        responseType: "customeOutlineInput",
        next:10
    },{
        id:10,
        delay: 200,
        apiCall: false,
        message:'Lets create a storyboard for this topic',
        responseType:'none',
        isNavigate:true,
        urlstart:'/ai-scenes?projectId=',
        urlEnd:'&source=AI',
        isParam:'&language='

    },{
        id: 212,
        delay: 1000,
        message: 'Alright! Enter URL of Webpage',
        responseType: "none",
        apiCall: false,
        next:21
    }
    ,{
        id:21,
        delay: 1000,
        apiCall: false,
        responseType:'url',
        next:22
    },
    {
        id:22,
        delay: 0,
        apiCall: false,
        message:'Navigating',
        responseType:'none',
        next:23
    },
    {
        id:23,
        delay: 0,
        apiCall: true,
        apiName:'urlAPI',
        responseType:'none',
        next:24,
        isNavigate:true,
        urlstart:'/scenes?projectId=',
        urlEnd:'&source=URL',
    },
    {
        id:31,
        delay: 200,
        apiCall: false,
        message:'Navigating',
        responseType:'none',
        isNavigate:true,
        url:'/templates',
        next:31
    },
    {
        id:616,
        delay: 200,
        apiCall: true,
        apiName:'textAPI',
        message:'Navigating',
        responseType:'none',
        isNavigate:true,
        urlstart:'/text-summarization?projectId=',
        urlEnd:'&source=text',
        next:24
    },
    {
        id: 414,
        delay: 1000,
        message: "Please enter project name",
        responseType: "none",
        apiCall: false,
        next:41
    },
  
    {
        id:41,
        delay: 1000,
        responseType: "text",
        apiCall: false,
        next:42
    },
    {
        id: 42,
        delay: 1000,
        message: "Creating a storyboard",
        apiCall: false,
        responseType: "none",
        next:43
    },
    {
        id: 43,
        delay: 0,
        apiCall: true,
        apiName:'scratchAPI',
        responseType: "none",
        isNavigate:true,
        urlstart: '/studio?projectId=',
        urlEnd:'',
    },
   
  ];
  